const asamCriteriaJSON = {
  section: "AC",
  topic: "ASAM Criteria",
  checked: "true",
  // access: "private",
  color: "secondary",
  description: "(optional)",
  body: [
    {
      id: "AC.1",
      shortname: "ac01",
      name: "1 – Acute Intoxication and/or Withdrawal Potential",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0: Non-issue or very low-risk issue.",
        "1: Mildly difficult issue or present minor signs and symptoms.",
        "2: Moderate difficulty in functioning.",
        "3: Serious issue or difficulty coping within a given dimension.",
        "4: Indicates utmost severity; critical impairments in coping and functioning.",
      ],
      act: "AC01",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "AC01-1",
    },
    {
      id: "AC.1.1",
      shortname: "ac01-1",
      name: "",
      prevalue: "Justification for the selected rating:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "AC01-1",
      mandatory: "true",
      depending: "AC01",
      exception: "",
      extend: "true",
    },
    {
      id: "AC.2",
      shortname: "ac02",
      name: "2 – Biomedical Conditions and Complications",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0: Non-issue or very low-risk issue.",
        "1: Mildly difficult issue or present minor signs and symptoms.",
        "2: Moderate difficulty in functioning.",
        "3: Serious issue or difficulty coping within a given dimension.",
        "4: Indicates utmost severity; critical impairments in coping and functioning.",
      ],
      act: "AC02",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "AC02-1",
    },
    {
      id: "AC.2.1",
      shortname: "ac02-1",
      name: "",
      prevalue: "Justification for the selected rating:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "AC02-1",
      mandatory: "true",
      depending: "AC02",
      exception: "",
      extend: "true",
    },
    {
      id: "AC.3",
      shortname: "ac03",
      name:
        "3 – Emotional, Behavioral, or Cognitive Conditions and Complications",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0: Non-issue or very low-risk issue.",
        "1: Mildly difficult issue or present minor signs and symptoms.",
        "2: Moderate difficulty in functioning.",
        "3: Serious issue or difficulty coping within a given dimension.",
        "4: Indicates utmost severity; critical impairments in coping and functioning.",
      ],
      act: "AC03",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "AC03-1",
    },
    {
      id: "AC.3.1",
      shortname: "ac03-1",
      name: "",
      prevalue: "Justification for the selected rating:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "AC03-1",
      mandatory: "true",
      depending: "AC03",
      exception: "",
      extend: "true",
    },
    {
      id: "AC.4",
      shortname: "ac04",
      name: "4 – Readiness to Change",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0: Non-issue or very low-risk issue.",
        "1: Mildly difficult issue or present minor signs and symptoms.",
        "2: Moderate difficulty in functioning.",
        "3: Serious issue or difficulty coping within a given dimension.",
        "4: Indicates utmost severity; critical impairments in coping and functioning.",
      ],
      act: "AC04",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "AC04-1",
    },
    {
      id: "AC.4.1",
      shortname: "ac04-1",
      name: "",
      prevalue: "Justification for the selected rating:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "AC04-1",
      mandatory: "true",
      depending: "AC04",
      exception: "",
      extend: "true",
    },
    {
      id: "AC.5",
      shortname: "ac05",
      name: "5 – Relapse, Continued Use, or Continued Problem Potential",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0: Non-issue or very low-risk issue.",
        "1: Mildly difficult issue or present minor signs and symptoms.",
        "2: Moderate difficulty in functioning.",
        "3: Serious issue or difficulty coping within a given dimension.",
        "4: Indicates utmost severity; critical impairments in coping and functioning.",
      ],
      act: "AC05",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "AC05-1",
    },
    {
      id: "AC.5.1",
      shortname: "ac05-1",
      name: "",
      prevalue: "Justification for the selected rating:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "AC05-1",
      mandatory: "true",
      depending: "AC05",
      exception: "",
      extend: "true",
    },
    {
      id: "AC.6",
      shortname: "ac06",
      name: "6 – Recovery/Living Environment",
      prevalue: "",
      value: "",
      disabled: "false",
      type: "select",
      ruletype: "string",
      select_options: [
        "",
        "0: Non-issue or very low-risk issue.",
        "1: Mildly difficult issue or present minor signs and symptoms.",
        "2: Moderate difficulty in functioning.",
        "3: Serious issue or difficulty coping within a given dimension.",
        "4: Indicates utmost severity; critical impairments in coping and functioning.",
      ],
      act: "AC06",
      mandatory: "true",
      depending: "",
      exception: "",
      link: "AC06-1",
    },
    {
      id: "AC.6.1",
      shortname: "ac06-1",
      name: "",
      prevalue: "Justification for the selected rating:",
      value: "",
      disabled: "true",
      type: "editor",
      act: "AC06-1",
      mandatory: "true",
      depending: "AC06",
      exception: "",
      extend: "true",
    },
    // {
    //   id: "AC.7",
    //   shortname: "ac07",
    //   access: "private",
    //   name: "Field for counselor: ",
    //   prevalue: "",
    //   value: "",
    //   disabled: "true",
    //   type: "editor",
    //   act: "AC07",
    //   depending: "",
    //   exception: "",
    // },
    // {
    //   id: "AC.7",
    //   shortname: "ac07",
    //   access: "private",
    //   name: "Field for counselor: ",
    //   prevalue: "",
    //   value: "",
    //   disabled: "false",
    //   type: "textarea",
    //   act: "AC07",
    //   depending: "",
    //   exception: "",
    // },
  ],
};

export default asamCriteriaJSON;
